import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectCoverflow,
} from "swiper";
import {
  Box,
  useTheme,
  Button,
  useMediaQuery,
  Typography,
  darken,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const TextCarousel = ({
  html,
  slidesPerView,
  spaceBetween,
  effect,
  pagination,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: darken(theme.palette.background.paper, 0.05),
      }}
      p={2}
      position="relative"
    >
      <Swiper
        effect={effect}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        className=""
        style={{
          "--swiper-pagination-color": theme.palette.primary.main,
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-navigation-color": theme.palette.primary.main,
          "--swiper-navigation-size": "30px",
        }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={false}
        pagination={pagination ? { clickable: true } : pagination}
        mousewheel={false}
        keyboard={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[
          Autoplay,
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          EffectCoverflow,
        ]}
      >
        {html?.map((item, index) => {
          return (
            <SwiperSlide key={index} onClick={onClick}>
              <Box pt={mobileView ? 0 : 2} pb={5} px={6}>
                {item}
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

TextCarousel.propTypes = {
  banners: PropTypes.array,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  effect: PropTypes.string,
  pagination: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

TextCarousel.defaultProps = {
  banners: [],
  slidesPerView: 1,
  spaceBetween: 20,
  effect: null,
  pagination: true,
  style: {
    "--swiper-navigation-color": "#FFF",
    "--swiper-navigation-size": "40px",
    "--swiper-pagination-color": "#FFF",
  },
  onClick: () => null,
  type: null,
};

export default TextCarousel;
