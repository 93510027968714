import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { clearAlert } from "../../features/slices/alertSlice";
import {
  selectAlertMessage,
  selectAlertVariant,
} from "../../features/slices/alertSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = ({ variant, message }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const alertMessage = useSelector(selectAlertMessage);
  const alertVariant = useSelector(selectAlertVariant);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
      return;
    }
    setOpen(false);
  };

  if (!open) {
    dispatch(clearAlert());
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={alertVariant || ""}
        sx={{ width: "100%" }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
