import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const HowToDeposit = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <Box my={4}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("tutorial.deposit.title")}
        </Typography>
        {/* <Typography variant="body1" component="div" fontWeight={800}>
          {t("tutorial.join.title")}
        </Typography> */}
        <Typography variant="body2" component="div">
          {t("tutorial.deposit.list1.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list1.1")}</li>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list1.2")}</li>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list1.3")}</li>
          </ol>
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("tutorial.deposit.list2.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list2.1")}</li>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list2.2")}</li>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list2.3")}</li>
            <li style={{ margin: "16px" }}>{t("tutorial.deposit.list2.4")}</li>
          </ol>
        </Typography>
      </Box>
    </Container>
  );
};

export default HowToDeposit;
