import React from "react";

const HomeIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      zoomAndPan="magnify"
      viewBox="0 0 810 809.999993"
      height={height}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="69381f3ab2">
          <path
            d="M 55.105469 107.589844 L 754.855469 107.589844 L 754.855469 702.339844 L 55.105469 702.339844 Z M 55.105469 107.589844 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#69381f3ab2)">
        <path
          fill={color}
          d="M 335.003906 702.335938 L 335.003906 492.425781 L 474.953125 492.425781 L 474.953125 702.335938 L 649.890625 702.335938 L 649.890625 422.457031 L 754.855469 422.457031 L 404.980469 107.589844 L 55.105469 422.457031 L 160.066406 422.457031 L 160.066406 702.335938 Z M 335.003906 702.335938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
};

export default HomeIcon;
