import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: null,
  variant: null,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      const { isOpen, message, variant } = action.payload;
      state.isOpen = isOpen;
      state.message = message;
      state.variant = variant;
    },
    clearAlert: (state, action) => {
      state.isOpen = false;
      state.message = null;
      state.variant = null;
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;

export default alertSlice.reducer;

export const selectAlertIsOpen = (state) => state.alert.isOpen;
export const selectAlertMessage = (state) => state.alert.message;
export const selectAlertVariant = (state) => state.alert.variant;
