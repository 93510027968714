import { apiSlice } from "../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Login"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Login"],
      invalidatesTags: ["Profile", "BonusList", "GameCategory"],
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
